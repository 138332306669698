import React from 'react';
import { graphql } from 'gatsby';
import Section from '@atoms/Section/Section';
// Modules
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const VersicherungPage = () => {
    return (
        <Layout>
            <Seo
                title="Welche Zusatzversicherungen übernehmen die Kosten für die Psychotherapie?"
                description="Zusatzversicherungen Liste - Aepsy"
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                {/* <Section theme="dark" container="large" spacingTop="xxl" spacingBottom="m">
					<Title theme="white" size="xl">
						Welche Zusatzversicherungen übernehmen die Kosten für die Psychotherapie?
					</Title>
				</Section> */}
                <Section theme="dark">
                    <Header
                        theme="dark"
                        title="Welche Zusatzversicherungen übernehmen die Kosten für die Psychotherapie?"
                        spacing="s"
                        size="thin"
                    />
                </Section>

                <Section container="short" spacing="m" theme="light">
                    <div className="BlogTemplate--wysiwyg">
                        <p>
                            <em>
                                Aepsy Liste der grössten Zusatzversicherungen
                                der Schweiz (über 100'000 Versicherte) und ihre
                                Kostenbeteiligung an der Psychotherapie.
                                Therapeutenlisten werden aufgeführt, wenn
                                vorhanden:
                            </em>
                        </p>
                        <h2 id="css">CSS</h2>
                        <ul>
                            <li>
                                <p>
                                    Alternativ-Versicherung myFlex; Übernahme
                                    von 75% der Kosten
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            bis max. CHF 1000.- / Kj. im
                                            Balance-Modell
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            bis max. CHF 3000.- / Kj. im
                                            Premium-Modell
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Es wird keine ärztliche Anordnung
                                        vorausgesetzt
                                    </strong>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste CSS: Wird nicht
                                    herausgegeben, Psychotherapeuten werden nach
                                    geographischer Nähe zugewiesen
                                </p>
                            </li>
                        </ul>
                        <h2 id="concordia">Concordia</h2>
                        <ul>
                            <li>
                                <p>DIVERSA; Übernahme von 75% der Kosten</p>
                                <ul>
                                    <li>
                                        <p>
                                            bis max. CHF 2000.- / 3 Jahre im
                                            normalen Modell
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            bis max. CHF 2000.- / 3 Jahre im
                                            Care-Modell
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            bis max. CHF 3000.- / 3 Jahre im
                                            Plus-Modell
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            bis max. CHF 3000.- / 3 Jahre im
                                            Premium-Modell
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Concordia: Mitglieder des
                                    Schweizerischen Psychotherapeuten-Verbandes
                                    (SPV) oder auf der Liste von santésuisse
                                    aufgeführt
                                </p>
                            </li>
                        </ul>
                        <h2 id="swica">Swica</h2>
                        <ul>
                            <li>
                                <p>
                                    COMPLETA TOP; 90% bis CHF 50.- pro Sitzung,
                                    max. 60 Sitzungen pro Jahr bei
                                    SWICA-anerkannten Therapeuten
                                </p>
                            </li>
                            <li>
                                <p>
                                    OPTIMA; + CHF 25.-, max. 60 Sitzungen pro
                                    Jahr bei SWICA-anerkannten Therapeuten
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Swica: Mitglieder, die auf
                                    der Liste von santésuisse aufgeführt sind
                                    und Psychotherapeuten FSP gemäss FSP
                                    Register
                                </p>
                            </li>
                        </ul>
                        <h2 id="assura">Assura</h2>
                        <ul>
                            <li>
                                <p>
                                    COMPLEMENTA EXTRA: bis max. CHF 1500.- /
                                    Jahr
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Assura: Mitglieder, die auf
                                    der Liste von santésuisse aufgeführt sind
                                </p>
                            </li>
                        </ul>
                        <h2 id="helsana">Helsana</h2>
                        <ul>
                            <li>
                                <p>
                                    TOP: Übernahme von 75% der Kosten bis max.
                                    CHF 3000.- / Jahr
                                </p>
                            </li>
                            <li>
                                <p>
                                    COMPLETA: Übernahme von 75% der Kosten bis
                                    max. CHF 4500.- / Jahr
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Helsana:{' '}
                                    <a href="https://www.helsana.ch/de/private/versicherungen/zusatzversicherungen/ambulant/top/psychotherapeuten.html">
                                        Zur Therapeutenliste der Helsana
                                    </a>
                                </p>
                            </li>
                        </ul>
                        <h2 id="atupri">Atupri</h2>
                        <ul>
                            <li>
                                <p>Mivita: Übernahme von 60% der Kosten</p>
                                <ul>
                                    <li>
                                        <p>
                                            bis max. CHF 1000.- / Kj. im
                                            Reala-Modell
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            bis max. CHF 1500.- / Kj. im
                                            Extensa-Modell
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Atupri: Anerkannt sind
                                    Psychologen und weitere Fachpersonen, die im
                                    Besitz einer kantonalen Bewilligung zur
                                    selbstständigen Praxisführung oder Mitglied
                                    eines der folgenden Verbände sind:
                                    Schweizerischer Psychotherapeuten­Verband
                                    (SPV); Schweizerischer Berufsverband für
                                    angewandte Psychologie (SBAP); Föderation
                                    der Schweizer Psychologinnen und Psychologen
                                    (FSP)
                                </p>
                            </li>
                        </ul>
                        <h2 id="kpt">KPT</h2>
                        <ul>
                            <li>
                                <p>
                                    Krankenpflege-Plus: Übernahme der Kosten bis
                                    max. CHF 1600.- innerhalb von 5
                                    Kalenderjahren, max. CHF 50.- pro Sitzung
                                </p>
                            </li>
                            <li>
                                <p>
                                    Krankenpflege-Comfort: Übernahme der Kosten
                                    bis max. CHF 1600.- innerhalb von 5
                                    Kalenderjahren, max. CHF 50.- pro Sitzung
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste KPT: Therapeuten müssen
                                    Mitglied des Schweizerischen
                                    Psychotherapeuten-Verbandes (SPV) oder
                                    Psychotherapeut FSP (Föderation Schweizer
                                    Psychologinnen und Psychologen) sein
                                </p>
                            </li>
                        </ul>
                        <h2 id="-kk">ÖKK</h2>
                        <ul>
                            <li>
                                <p>
                                    ÖKK START: Übernahme von 50% der Kosten bis
                                    CHF 1000.- / Kj.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste ÖKK: Eine Liste wird nicht
                                    ausgehändigt; der Psychotherapeut muss
                                    zusätzlich über eine kantonale Bewilligung
                                    zur selbstständigen Praxisführung verfügen
                                </p>
                            </li>
                        </ul>
                        <h2 id="sanitas">Sanitas</h2>
                        <ul>
                            <li>
                                <p>
                                    Classic: Übernahme von 80% der Kosten bis
                                    max. 1000.- pro Kj.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Family: Übernahme von 80% der Kosten bis
                                    max. 1000.- pro Kj.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Sanitas: Keine Liste
                                    vorhanden, Therapeut muss Mitglied der
                                    Föderation Schweizer Psychologinnen und
                                    Psychologen (FSP) sein
                                </p>
                            </li>
                        </ul>
                        <h2 id="groupe-mutuel-und-avenir">
                            Groupe Mutuel und Avenir
                        </h2>
                        <ul>
                            <li>
                                <p>Heilungskosten-Zusatzversicherung</p>
                                <ul>
                                    <li>
                                        <p>
                                            Stufe 1: Übernahme von CHF 500 / Kj.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Stufe 2: Übernahme von CHF 600 / Kj.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Stufe 3: Übernahme von CHF 700 / Kj.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Stufe 4: Übernahme von CHF 800 / Kj.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Eine ärztliche Anordnung wird vorausgesetzt
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Groupe Mutuel: Die
                                    Krankenkasse muss kontaktiert werden, um
                                    abzuklären, ob der/die betreffende
                                    Therapeut/in anerkannt ist
                                </p>
                            </li>
                        </ul>
                        <h2 id="visana">Visana</h2>
                        <ul>
                            <li>
                                <p>
                                    Basic: Übernahme von CHF 60.- / Sitzung für
                                    die ersten 20 Sitzungen, danach CHF 50.- /
                                    Sitzungen für 40 weitere Sitzungen
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ambulant II: Übernahme von CHF 60.- /
                                    Sitzung für die ersten 20 Sitzungen, danach
                                    CHF 50.- / Sitzungen für 40 weitere
                                    Sitzungen
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ambulant III: Übernahme von 80% bis max. CHF
                                    5000.- / Jahr; bei Ärzten im Ausstand 90%
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Es wird keine ärztliche Anordnung
                                        vorausgesetzt
                                    </strong>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Therapeutenliste Visana:{' '}
                                    <a href="https://entry.visana.ch/b2a/apps/saa/thera/">
                                        Zur Therapeutenliste von Visana
                                    </a>
                                </p>
                            </li>
                        </ul>
                        <p>
                            Alle aufgeführten Daten wurden aktuell erhoben oder
                            im November 2020 aktualisiert.
                        </p>
                        <p>
                            Änderungen durch die Krankenkassen bleiben
                            vorbehalten. Jede Haftung wird ausgeschlossen.
                        </p>
                        <p>Aepsy</p>
                        <p>November 2020</p>
                    </div>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(VersicherungPage);
